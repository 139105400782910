.winner-component {
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.968),
      rgba(255, 245, 255, 0.968)
    ),
    url("../../../assets/bald-2.jpg");
  background-size: 50px auto;
  font-weight: 500;
  font-family: "Gloria Hallelujah", cursive;
  padding: 0 5rem;
}

.winner-component h1 {
  margin-top: 0;
  padding: 1rem;
}

.winner-component .t-1 {
  width: 80%;
  margin: auto;
  margin-top: 3rem;
  font-size: 2rem;
}

.winner-component .img-div {
  height: 300px;
  overflow: hidden;
  margin: 1rem;
}

.winner-component .img-div img {
  height: 100%;
  width: auto;
  padding: 0 1rem;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 5rem 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 600px;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 900px) {
  .winner-component {
    padding: 0 1rem;
  }
}
