.question-tab {
  margin: 2rem 0;
  padding: 20px 2rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  width: 900px;
}

.question-tab .options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.question-tab .colorGreen {
  color: rgb(2, 79, 2);
  font-size: 20px;

}

.question-tab .options .option {
  flex: 1;
  font-size: 1.2rem;
  text-align: center;
  padding: 20px 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.question-tab .options .option span {
  margin-left: 10px;
}

.question-tab .question {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.question-tab .sure {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem 0;
}

.question-tab .sure button {
  border: 2px solid var(--color-two);
  color: var(--color-two);
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 1px;
  background-color: #fff;
  transition: 0.5s;
  border-radius: 10px;
}

.question-tab .sure button:hover {
  box-shadow: 0 0.8em 0.8em -0.6em var(--color-one);
  transform: translateY(-0.25em);
  background: var(--color-two);
  color: white;
}

.question-tab .lock input,
.question-tab .lock button {
  pointer-events: none;
}

.correct {
  background: rgba(32, 174, 32, 0.564);
}

.incorrect {
  background: rgba(135, 13, 13, 0.707);
}
.none {
  display: none;
}

.question-tab [type="radio"] {
  height: 25px;
  width: 25px;
  vertical-align: middle;
}
