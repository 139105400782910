.receivermessage {
  align-self: flex-start;
  background: white;
  padding: 5px;
  width: fit-content;
  border-radius: 10px;
  border-bottom: 2px solid rgba(37, 37, 37, 0.075);
  margin-bottom: 2px;

  max-width: 400px;
  width: fit-content;

}
.receivermessage > p {
  padding: 5px;
  margin: 0;
  overflow-wrap: break-word;
}

.timeee {
  padding: 2px 5px;
  font-size: 0.9em;
  color: #3f5330;
  display: flex;
  align-items: center;
  margin-left: 40px;
}
