.chatarea {
  width: 100%;
  background-color: #51b7a1;
  border-left: 2px solid rgb(220, 195, 227);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.chatarea .header {
  text-align: center;
}

.chatarea .messages {
  overflow-y: auto;
}
