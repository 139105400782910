.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.home h1 span {
  font-family: potta, sans-serif;
  font-size: 3rem;
  letter-spacing: 3px;
}

.home h1 {
  color: var(--color-one);
  font-size: 4rem;
  margin: auto 1rem !important;
}

.home h2 {
  color: var(--color-two);
  font-size: 2rem;
}

.home .training {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 40vh;
}

.home .training .not-really {
  font-size: 0.9rem;
}
.home .points {
  position: fixed;
  right: 0;
  top: 0;
  margin: 1rem;
  font-size: 2rem;
  font-weight: 600;
  border: 2px var(--color-three) solid;
  padding: 0.2rem 1rem;
  background: rgba(157, 191, 157, 0.421);
  filter: blur(0.2px);
  border-radius: 5px;
}

.home .end-message {
  font-size: 2rem;
  margin: 2rem 0;
}

.home .end-message a {
  text-decoration: none;
  margin: 0 10px;
}
