@import url(https://fonts.googleapis.com/css2?family=Aldrich&display=swap);
body {
  font-family: "Satisfy", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffaef;
  margin: 0;
}

@font-face {
  font-family: "potta";
  src: local("potta"), url(/static/media/HarryP-MVZ6w.a65869c0.ttf) format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-one: #740001;
  --color-two: #005f60;
  --color-three: #004c2e;
}

.App {
  margin: auto;
  height: 100vh;
}

.logout {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d7fa3e;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.home h1 span {
  font-family: potta, sans-serif;
  font-size: 3rem;
  letter-spacing: 3px;
}

.home h1 {
  color: var(--color-one);
  font-size: 4rem;
  margin: auto 1rem !important;
}

.home h2 {
  color: var(--color-two);
  font-size: 2rem;
}

.home .training {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 40vh;
}

.home .training .not-really {
  font-size: 0.9rem;
}
.home .points {
  position: fixed;
  right: 0;
  top: 0;
  margin: 1rem;
  font-size: 2rem;
  font-weight: 600;
  border: 2px var(--color-three) solid;
  padding: 0.2rem 1rem;
  background: rgba(157, 191, 157, 0.421);
  -webkit-filter: blur(0.2px);
          filter: blur(0.2px);
  border-radius: 5px;
}

.home .end-message {
  font-size: 2rem;
  margin: 2rem 0;
}

.home .end-message a {
  text-decoration: none;
  margin: 0 10px;
}

.question-tab {
  margin: 2rem 0;
  padding: 20px 2rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  width: 900px;
}

.question-tab .options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.question-tab .colorGreen {
  color: rgb(2, 79, 2);
  font-size: 20px;

}

.question-tab .options .option {
  flex: 1 1;
  font-size: 1.2rem;
  text-align: center;
  padding: 20px 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.question-tab .options .option span {
  margin-left: 10px;
}

.question-tab .question {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.question-tab .sure {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem 0;
}

.question-tab .sure button {
  border: 2px solid var(--color-two);
  color: var(--color-two);
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 1px;
  background-color: #fff;
  transition: 0.5s;
  border-radius: 10px;
}

.question-tab .sure button:hover {
  box-shadow: 0 0.8em 0.8em -0.6em var(--color-one);
  transform: translateY(-0.25em);
  background: var(--color-two);
  color: white;
}

.question-tab .lock input,
.question-tab .lock button {
  pointer-events: none;
}

.correct {
  background: rgba(32, 174, 32, 0.564);
}

.incorrect {
  background: rgba(135, 13, 13, 0.707);
}
.none {
  display: none;
}

.question-tab [type="radio"] {
  height: 25px;
  width: 25px;
  vertical-align: middle;
}

.winner-component {
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.968),
      rgba(255, 245, 255, 0.968)
    ),
    url(/static/media/bald-2.33500768.jpg);
  background-size: 50px auto;
  font-weight: 500;
  font-family: "Gloria Hallelujah", cursive;
  padding: 0 5rem;
}

.winner-component h1 {
  margin-top: 0;
  padding: 1rem;
}

.winner-component .t-1 {
  width: 80%;
  margin: auto;
  margin-top: 3rem;
  font-size: 2rem;
}

.winner-component .img-div {
  height: 300px;
  overflow: hidden;
  margin: 1rem;
}

.winner-component .img-div img {
  height: 100%;
  width: auto;
  padding: 0 1rem;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 5rem 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 600px;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 900px) {
  .winner-component {
    padding: 0 1rem;
  }
}

.wordle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(238, 245, 238, 0.428);
  position: relative;
  font-family: 'Roboto Condensed', sans-serif!important;
}
.wordle-container .modal{
  position: absolute;

  height: 100px;
  width: 200px;

  background-color: white;
}
.wordle-container h1 {
  margin: 10px 0;
}
.wordle-small {
  width: 360px;
  background-color: rgb(79, 124, 135);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 2rem;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.wordle-small .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 15px 10px;
}

.wordle-small .row .item {
  width: 80px;
  height: 50px;
  border: 0.5px solid rgba(14, 15, 14, 0.551);
  background-color: rgba(18, 19, 18, 0.551);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;

  color: beige;
}

.wordle-small input {
  margin: 30px 4rem 10px 4rem;
  padding: 10px 2px;
  font-size: 1.5rem;
  text-align: center;
}

.black {
  background-color: rgb(62, 29, 29) !important;
}
.green {
  background-color: rgb(44, 115, 44) !important;
}
.yellow {
  background-color: #cfbd21 !important;
}

.keyboard{
  width: 500px;
}

.sory h2 {
  padding: 0 20px;
}
.sory a{
    text-decoration: none;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
    display: block;
}

.sory .flip-card {
  width: 200px;
  height: 200px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;

  transition: 0.2s;

  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.sory .diff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;

  justify-content: center;
}

.sory .diff .deep,
.sory .diff .aaru {
    margin: 20px;
    cursor: pointer;
}

.flip-90 {
  transform: rotateY(180deg);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Aldrich", sans-serif;
}

:root {
  --primary-color: rgb(248, 210, 83);
  --secondary-color: rgb(84, 186, 78);
  --tertiary-color: rgb(248, 249, 204);
}
body {
  overflow-x: hidden;
}
.container {
  background-color: rgb(248, 249, 204);
  background-color: var(--tertiary-color);
  max-width: 430px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  overflow-x: hidden;
}
.profile {
  height: 22em;
  width: 16em;
  margin-top: 3.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.profile-box {
  height: 22em;
  width: 16em;
  background-color: rgb(248, 210, 83);
  background-color: var(--primary-color);
  border: 2px solid black;
  position: relative;
  bottom: 0;
  left: 0;
  box-shadow: -11px 11px 0 -3px black;
  transition: box-shadow 600ms ease-out, bottom 600ms ease-out,
    left 600ms ease-out;
}
.profile-box:hover {
  box-shadow: -3px 3px 0 -3px black;
  bottom: -8px;
  left: -8px;
}

.profile-head {
  width: 100%;
  height: 1.5em;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0.3em;
}
.profile-button {
  width: 0.8em;
  height: 0.8em;
  border: 2px solid black;
  border-radius: 50%;
  margin: 0 0.3em;
}

.profile-photo {
  height: 19em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;
}
#toghiri-photo {
  width: 15em;
}
.frist-star {
  position: absolute;
  z-index: -1;
  top: -2.2em;
  right: -2.5em;
  animation: star-rotate 4s infinite;
}

.line {
  position: absolute;
  top: 0;
  left: -5.6em;
  z-index: -2;
  margin-top: 4em;
  width: 430px;
  height: 2px;
  background-color: black;
  box-shadow: 0 1.5em black, 0 3em black, 0 4.5em black, 0 6em black,
    0 7.5em black, 0 9em black;
}

@keyframes star-rotate {
  0% {
    transform: rotate(0turn);
  }
  50% {
    transform: rotate(1turn);
  }
  100% {
    transform: rotate(0turn);
  }
}

.profile .pencil {
  position: absolute;
  z-index: 2;
  top: -1em;
  left: 2em;
}

.arrow1 {
  width: 40%;
  position: absolute;
  stroke-width: 2px;
  stroke: black;
  right: -1.5em;
  bottom: -6.9em;
  fill: transparent;
}

/* section 2 - name and family box */

.name {
  margin-top: 3em;
  width: 13em;
  height: 4em;
  position: relative;
  left: -2.8em;
}

.name-box {
  width: 12em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: drop-shadow(-5px 5px 0 black);
          filter: drop-shadow(-5px 5px 0 black);
  position: relative;
  bottom: 0;
  left: 0;
  transition: bottom 500ms ease-out, left 500ms ease-out, -webkit-filter 500ms ease-out;
  transition: filter 500ms ease-out, bottom 500ms ease-out, left 500ms ease-out;
  transition: filter 500ms ease-out, bottom 500ms ease-out, left 500ms ease-out, -webkit-filter 500ms ease-out;
}
.box1 {
  width: 12em;
  position: relative;
}
.name-box:hover {
  bottom: -5px;
  left: -5px;
  -webkit-filter: drop-shadow(0 0 black);
          filter: drop-shadow(0 0 black);
}
.name-box h1 {
  font-size: 1.1em;
  position: absolute;
  margin-left: 0.6em;
  color: black;
}

.arrow2 {
  position: absolute;
  width: 40%;
  stroke-width: 2px;
  stroke: black;
  bottom: -3.8em;
}
.key {
  width: 2em;
  position: absolute;
  right: -1.6em;
  top: 2.5em;
}

/* section 3 - feild */

.feild {
  margin-top: 3em;
  width: 14em;
  height: 4.5em;
  position: relative;
  bottom: 0;
  left: 2em;
}

.feild-box {
  width: 14em;
  height: 4.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  -webkit-filter: drop-shadow(-5px 5px 0 black);
          filter: drop-shadow(-5px 5px 0 black);
  transition: bottom 500ms ease-out, left 500ms ease-out, -webkit-filter 500ms ease-out;
  transition: filter 500ms ease-out, bottom 500ms ease-out, left 500ms ease-out;
  transition: filter 500ms ease-out, bottom 500ms ease-out, left 500ms ease-out, -webkit-filter 500ms ease-out;
}
.box2 {
  width: 14em;
  position: relative;
}
.feild-box:hover {
  bottom: -5px;
  left: -5px;
  -webkit-filter: drop-shadow(0 0 black);
          filter: drop-shadow(0 0 black);
}

.feild-box h2 {
  font-size: 0.8em;
  position: absolute;
  align-self: center;
  margin-left: 0.4em;
  color: black;
  line-height: 1.5em;
}

.arrow3 {
  position: absolute;
  width: 50%;
  stroke: black;
  stroke-width: 2px;
  right: -0.8em;
  bottom: -6.2em;
}
.suitable {
  position: absolute;
  left: -3em;
  bottom: -1em;
  width: 2em;
}

/* section 4 - about me */

.aboutme {
  width: 16em;
  height: 16em;
  background-color: rgb(248, 210, 83);
  background-color: var(--primary-color);
  margin-top: 3em;
  margin-bottom: 3em;
  border: 2px solid black;
  position: relative;
  left: -1em;
}
.aboutme::after {
  content: "";
  width: 15.7em;
  height: 15.7em;
  background-color: rgb(248, 210, 83);
  background-color: var(--primary-color);
  border: 2px solid black;
  position: absolute;
  top: 0.4em;
  left: 0.4em;
  z-index: -1;
}
.aboutme::before {
  content: "";
  width: 15.7em;
  height: 15.7em;
  background-color: rgb(248, 210, 83);
  background-color: var(--primary-color);
  border: 2px solid black;
  position: absolute;
  top: 0.8em;
  left: 0.8em;
  z-index: -2;
}
.title-aboutme p {
  font-size: 1em;
  padding-top: 1em;
  text-align: center;
  font-weight: bold;
}

.aboutme .text-aboutme {
  font-size: 0.8em;
  padding: 1em;
  text-align: center;
}

.second-star {
  position: absolute;
  bottom: -3em;
  left: -2.5em;
  z-index: -4;
  animation: star-rotate 4s infinite;
}

/* sand timer  */
.watch {
  position: absolute;
  top: 5em;
  right: -3em;
}

.soil rect {
  stroke: black;
  stroke-width: 1.01527px;
}
.soil {
  animation: timer 15s ease-in infinite forwards;
}
@keyframes timer {
  0% {
    -webkit-clip-path: polygon(30% 0, 70% 0, 85% 40%, 15% 40%);
            clip-path: polygon(30% 0, 70% 0, 85% 40%, 15% 40%);
  }
  6% {
    -webkit-clip-path: polygon(30% 0, 70% 0, 85% 40%, 15% 40%);
            clip-path: polygon(30% 0, 70% 0, 85% 40%, 15% 40%);
  }
  50% {
    -webkit-clip-path: polygon(40% 35%, 60% 35%, 60% 100%, 40% 100%);
            clip-path: polygon(40% 35%, 60% 35%, 60% 100%, 40% 100%);
  }
  80% {
    -webkit-clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
            clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
            clip-path: polygon(0 55%, 100% 55%, 100% 100%, 0 100%);
  }
}

/* section 4 - Rotation 1 */
.rotation {
  width: 100%;
  height: 10em;
  margin-top: 0.3em;
  position: relative;
  margin-bottom: 0.5em;
}
.hit-rotation {
  position: absolute;
  width: 5em;
  height: 2px;
  background-color: black;
  transform: rotate(30deg);
  right: 2em;
  top: -3.1em;
}
.circle-rotation {
  position: absolute;
  right: 4em;
  top: -3.3em;
}
.ground-rotation {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  transform: rotate(-15deg);
  top: 6em;
}

/* section 5 - media */
.media-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 3em;
  gap: 3em;
  padding: 3%;
}

.social-media-center {
  display: flex;
}
.social-media-center:nth-child(even) {
  justify-self: start;
}
.social-media-center:nth-child(odd) {
  justify-self: end;
}

.media {
  width: 100%;
  text-align: right;
  margin-right: 40%;
  margin-bottom: 2em;
}
.media h3 {
  color: black;
  font-size: 1.2em;
}

.social-media {
  width: 8em;
  height: 11.5em;
  background-color: rgb(248, 210, 83);
  background-color: var(--primary-color);
  margin-bottom: 2em;
  border: 2px solid black;
  box-shadow: -9px 9px 0 -3px black;
  padding: 0.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 0;
  bottom: 0;
  transition: box-shadow 500ms ease-out, left 500ms ease-out,
    bottom 500ms ease-out;
}
.social-media:hover {
  box-shadow: -3px 3px 0 -3px;
  left: -6px;
  bottom: 6px;
}
.social-media:active .click-here {
  background-color: rgb(248, 249, 204);
  background-color: var(--tertiary-color);
}

.social-image {
  width: 100%;
  height: 4.2em;
  border: 2px solid black;
  margin-bottom: 0.4em;

  display: flex;
  align-items: center;
  justify-content: center;
}
.social-image img {
  width: 100px;
}
.piccc {
  width: auto;
  height: 83px;
  position: absolute;
  z-index: 0;
}
.social-media > p {
  font-size: 0.7em;
  color: black;
  margin-bottom: 0.7em;
}
.click-here p {
  margin: 0;
}
.social-media h3 {
  font-size: 1.3em;
  color: black;
  margin-bottom: 0.5em;
}
.social-media div p {
  font-size: 0.8em;
}
.social-media .click-here {
  border: 2px solid black;
  padding: 3px 8px;
  border-radius: 1.5em;
  background-color: rgb(84, 186, 78);
  background-color: var(--secondary-color);
}

.social-media-center a {
  text-decoration: none;
  color: black;
}
.social-media-center:hover a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

/* rotation 2 */
.happy-man-icon {
  width: 4em;
  position: absolute;
  top: -3.5em;
  left: 5em;
  transform: rotate(15deg);
}
.line-animation {
  width: 100%;
  height: 2px;
  background-color: black;
  transform: rotate(-15deg);
  position: absolute;
  top: 5em;
}

/* section 6 - contact me */
.contact-father {
  position: relative;
}
.contact {
  width: 16em;
  height: 4em;
  background-color: rgb(248, 210, 83);
  background-color: var(--primary-color);
  border: 2px solid black;
  box-shadow: -9px 9px 0 -3px black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  right: -1em;
  bottom: 0;
  transition: box-shadow 500ms ease-out, bottom 500ms ease-out,
    right 500ms ease-out;
}
.contact:hover {
  box-shadow: -3px 3px 0 -3px black;
  right: -6px;
  bottom: -6px;
}
.contact div {
  width: 2em;
  height: 2em;
  border: 2px solid black;
  background-color: rgb(84, 186, 78);
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0;
  bottom: 0;
  box-shadow: -4px 4px 0 -2px;
  transition: box-shadow 300ms ease-out, bottom 300ms ease-out,
    right 300ms ease-out;
}
.contact div:hover {
  box-shadow: -2px 2px 0 -2px;
  bottom: -2px;
  right: 2px;
}
.contact div:active {
  background-color: rgb(248, 249, 204);
  background-color: var(--tertiary-color);
}

.contact div img {
  width: 1.1em;
}
.contact a {
  text-decoration: none;
  color: black;
}

.arrow5 {
  position: absolute;
  bottom: -7em;
  left: -0.2em;
  width: 50%;
  stroke-width: 1px;
  stroke: black;
  z-index: -1;
}

/* section 7 and 8 */
/* number */
.number {
  margin-top: 3em;
  width: 9em;
  height: 3em;
  position: relative;
  right: -3em;
}

.number-box {
  width: 9em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  -webkit-filter: drop-shadow(-4px 4px 0 black);
          filter: drop-shadow(-4px 4px 0 black);
  transition: bottom 500ms ease-out, left 500ms ease-out, -webkit-filter 500ms ease-out;
  transition: filter 500ms ease-out, bottom 500ms ease-out, left 500ms ease-out;
  transition: filter 500ms ease-out, bottom 500ms ease-out, left 500ms ease-out, -webkit-filter 500ms ease-out;
}
.box3 {
  width: 9em;
  position: relative;
}
.number-box:hover {
  bottom: -4px;
  left: -4px;
  -webkit-filter: drop-shadow(0 0 black);
          filter: drop-shadow(0 0 black);
}

.number-box h4 {
  font-size: 0.8em;
  position: absolute;
  align-self: center;
  margin-left: 0.5em;
  color: black;
}
.arrow6 {
  position: absolute;
  z-index: -3;
  width: 9em;
  top: 1.5em;
  right: -5.4em;
  stroke: black;
}
/* email */
.email {
  margin-top: 1em;
  margin-bottom: 10em;
  width: 11em;
  height: 3em;
  position: relative;
  left: -2.5em;
  top: 0.5em;
}

.email-box {
  width: 11em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 0;
  bottom: 0;
  -webkit-filter: drop-shadow(-4px 4px 0 black);
          filter: drop-shadow(-4px 4px 0 black);
  transition: bottom 700ms ease-out, left 700ms ease-out, -webkit-filter 700ms ease-out;
  transition: filter 700ms ease-out, bottom 700ms ease-out, left 700ms ease-out;
  transition: filter 700ms ease-out, bottom 700ms ease-out, left 700ms ease-out, -webkit-filter 700ms ease-out;
}

.box4 {
  width: 12em;
  position: relative;
}
.email-box:hover {
  bottom: -4px;
  left: -4px;
  -webkit-filter: drop-shadow(0 0 black);
          filter: drop-shadow(0 0 black);
}

.email-box h4 {
  font-size: 0.8em;
  position: absolute;
  align-self: center;
  margin-left: 0.5em;
  color: black;
}

/* responsive */

@media only screen and (max-width: 320px) {
  /* section 1 */
  .profile {
    height: 16em;
    width: 12em;
  }
  .profile-box {
    height: 16em;
    width: 12em;
  }
  .profile-head {
    width: 100%;
    height: 1em;
  }
  .profile-button {
    width: 0.6em;
    height: 0.6em;
    margin: 0 0.2em;
  }

  #toghiri-photo {
    width: 12em;
  }
  .arrow1 {
    right: -1.6em;
    bottom: -5.2em;
  }
  .profile .pencil {
    top: -1.3em;
    left: 1em;
  }

  /* section 2 */

  .name {
    margin-top: 2em;
    width: 11em;
    height: 3.5em;
    left: -2.8em;
  }

  .name-box {
    width: 11em;
    height: 3.5em;
  }
  .box1 {
    width: 11em;
  }

  .name-box h1 {
    font-size: 0.9em;
  }
  .arrow2 {
    bottom: -3.4em;
  }
  .key {
    top: 2.2em;
    right: -2.5em;
  }

  /* section 3 - feild */

  .feild {
    margin-top: 2em;
    left: 1em;
  }
  .box2 {
    width: 12.5em;
  }
  .feild-box h2 {
    font-size: 0.7em;
  }

  .arrow3 {
    right: 0.2em;
    bottom: -6.1em;
  }
  .suitable {
    left: -1.7em;
    bottom: -0.6em;
    width: 1.8em;
  }

  /* section 4 - about me */
  .aboutme {
    width: 13em;
    height: 19em;
    margin-top: 3em;
    margin-bottom: 3em;
  }
  .aboutme::after {
    width: 12.7em;
    height: 18.7em;
  }
  .aboutme::before {
    width: 12.7em;
    height: 18.7em;
  }

  /* section 6 - contact me - number  */
  .media-grid {
    grid-gap: 1em;
    gap: 1em;
  }

  .contact {
    right: -0.5em;
  }

  .number {
    right: -3em;
  }
}

.chat-box-daddy {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}

.chat-box-daddy .header h1 {
  color: #fff;
}

.receivermessage {
  align-self: flex-start;
  background: white;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  border-bottom: 2px solid rgba(37, 37, 37, 0.075);
  margin-bottom: 2px;

  max-width: 400px;
  width: fit-content;

}
.receivermessage > p {
  padding: 5px;
  margin: 0;
  overflow-wrap: break-word;
}

.timeee {
  padding: 2px 5px;
  font-size: 0.9em;
  color: #3f5330;
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.sendermessage {
    align-self: flex-end;
    background: #dff8ca;
    padding: 5px;
    
    border-radius: 10px;
    border-bottom: 2px solid rgba(37, 37, 37, 0.075);
    margin-bottom: 2px;
    max-width: 400px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .sendermessage > p {
    padding: 5px;
    margin: 0;
    overflow-wrap: break-word;
  }
  
  .sendermessage > .timeee {
    float: right;
    padding: 10px 5px;
    font-size: 0.9em;
    color: #3f5330;
    display: flex;
    align-items: center;
  }

  .whoileee{
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    padding: 0 10px;
  }
.chatarea {
  width: 100%;
  background-color: #51b7a1;
  border-left: 2px solid rgb(220, 195, 227);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.chatarea .header {
  text-align: center;
}

.chatarea .messages {
  overflow-y: auto;
}

.donkey-brains {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.donkey-brains img {
    height: 90vh;
}

