.chat-box-daddy {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}

.chat-box-daddy .header h1 {
  color: #fff;
}
