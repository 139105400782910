
.sory h2 {
  padding: 0 20px;
}
.sory a{
    text-decoration: none;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
    display: block;
}

.sory .flip-card {
  width: 200px;
  height: 200px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;

  transition: 0.2s;

  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.sory .diff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  justify-content: center;
}

.sory .diff .deep,
.sory .diff .aaru {
    margin: 20px;
    cursor: pointer;
}

.flip-90 {
  transform: rotateY(180deg);
}
