.sendermessage {
    align-self: flex-end;
    background: #dff8ca;
    padding: 5px;
    
    border-radius: 10px;
    border-bottom: 2px solid rgba(37, 37, 37, 0.075);
    margin-bottom: 2px;
    max-width: 400px;
    width: fit-content;
  }
  .sendermessage > p {
    padding: 5px;
    margin: 0;
    overflow-wrap: break-word;
  }
  
  .sendermessage > .timeee {
    float: right;
    padding: 10px 5px;
    font-size: 0.9em;
    color: #3f5330;
    display: flex;
    align-items: center;
  }

  .whoileee{
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    padding: 0 10px;
  }