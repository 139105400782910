.donkey-brains {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.donkey-brains img {
    height: 90vh;
}
